import React from "react"
import styled from "styled-components"
import widths from "../utils/dimensions"

let Content = styled.div`
  width: 100%;
  text-align: left;
  padding: 70px 0 50px 0;
  position: relative;
  display: ${ props => props.desktop ? 'none' : 'inline-block' };
  h1{
    text-transform: uppercase;
    max-width: 800px;
    margin: 0 auto 20px auto;
    line-height: 1.25;
  } 
  h2{
    text-transform: uppercase;
    max-width: 800px;
    margin: 0 auto 20px auto;
  }
  p{
    max-width: 800px;
    margin: auto;
  }
  @media screen and (min-width: ${ widths.mobileMenuBreakPoint }){
    display: ${ props => props.mobile ? 'none' : 'inline-block' };
    padding: 100px 0 80px 0;
  } 
`
let ContentInner = styled.div`
  width: ${ props => props.width ? props.width : widths.contentWidth };
  max-width: 90%;
  margin: auto;
  position: relative;
  color: ${ props => props.colour ? props.colour : 'black' };
`
let Background = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  background: ${ props => props.backGColour ? props.backGColour : 'rgba(255,255,255,0)' };
  z-index: -1;
`

const Section = ({
      children,
      colour,
      backGColour,
      backGAngle,
      width,
      mobile,
      desktop,
   }) => {
    return(
      <Content
        mobile={ mobile }
        desktop={ desktop }
      >
        <Background
          backGColour={ backGColour }
          backGAngle={ backGAngle }
        />
        <ContentInner
          colour={ colour }
          width={ width }
        >
          { children }
        </ContentInner>
      </Content>
    )
}
export default Section