import React from "react"
import styled from "styled-components"
import SEO from "../components/seo"

let Content = styled.div`
  text-align: center;
`

const Page = ({ children, seoTitle, seoDescription }) => {
  return (
    <Content>
      <SEO
        title={ seoTitle }
        description={ seoDescription }
      />

      { children }

    </Content>
  )
}
export default Page
